
@-webkit-keyframes animateRotate /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes animateRotate {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.animateRotate {
    -webkit-animation:  animateRotate 1.0s linear infinite;
    -moz-animation:     animateRotate 1.0s linear infinite;
    -ms-animation:      animateRotate 1.0s linear infinite;
    -o-animation:       animateRotate 1.0s linear infinite;
    animation:          animateRotate 1.0s linear infinite;
}
